/* You can add global styles to this file, and also import other style files */
@import '~normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;700;900&display=swap');

html {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    
}

* {
    box-sizing: border-box;
    font-family: Raleway;
}
